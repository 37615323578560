@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.0/font/bootstrap-icons.css");

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap'); /* duolingoesque */

/* test fonts */
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

/*global config & reset*/
* {
  margin: 0px 0px;
  padding: 0px 0px;
  
  font-family: 'Nunito Sans', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Open Sans', sans-serif;
}
/* html, body {margin: 0; height: 100%; overflow: hidden} */

body {
  margin: 0px 0px;
  background-color: var(--bgc);
  color: var(--color);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-secondary);
  opacity: 1; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
* ::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html {
  scroll-behavior: smooth;
}

:root {
  --tint-color: var(--color-twitter-blue);
  --tint-color-translucent: var(--color-translucent-twitter-blue);
  --tint-color: var(--color-discord);
  --tint-color-translucent: var(--color-translucent-discord);

  --tc: #7288d9;
  --tct: rgb(114, 137, 218, 0.25);

  --font-family: 'Nunito Sans', sans-serif;


  /* dark mode */
  --bgc-d: black;
  --bgc-light-d: rgb(21,24,28);     /* twitter */
  --bgc-semilight-d: rgb(15, 17, 19);
  --bgc-hover-d: rgb(249,252,255, 0.08);
  --bgc-nav-d: var(--bgc-light-d); 
  --bgc-settings-d: black;    
  --bgc-input-d: rgb(38, 41, 45);  
  --bc-d: rgba(48,54,58,255);         /* robinhood */
  --bc-chart-d: var(--bgc-hover-d);
  --color-d: rgb(205,205,205);
  --color-header-d: rgb(235, 235, 235);
  --color-secondary-d: rgb(147, 151, 156);
  --color-tertiary-d: rgb(121, 124, 125);
  --color-yellow-text-d: var(--color-yellow);
  --box-shadow-d: none;
  --box-shadow-dark-d: none;
  --float-border-d: 1px solid var(--bc);

  /*light mode*/
  --bgc-l: rgb(247, 249, 250);
  --bgc-l: rgb(244, 246, 248);
  --bgc-light-l: white;/* firebase */
  --bgc-hover-l: rgb(245,247,249);
  --bgc-semilight-l: rgb(248, 250, 252);
  --bgc-nav-l: var(--bgc-light-l);
  --bgc-settings-l: white;
  --bgc-input-l: rgb(225, 228, 232); /* twitter */
  --bgc-input-l: rgb(235, 238, 240);
  --bc-l: rgb(220, 227, 231);
  --bc-l: rgb(215, 222, 226);
  --bc-chart-l: rgb(230, 230, 230);
  --color-l: rgb(60,64,67);
  --color-header-l: rgb(30, 34, 37);
  --color-header-l: rgb(10, 12, 14);
  --color-secondary-l: rgba(120,124,126,255);
  --color-secondary-l: rgba(135,138,140,255);
  --color-tertiary-l: rgb(102,102,102);
  --color-yellow-text-l: #85681d;

  --float-border-l: 1px solid var(--bc);

  --box-shadow-l: 0px 1px 4px 0px rgba(0,0,0,0.2);
  --box-shadow-l: 0 0 20px rgba(0,0,0,0.05);
  --float-border-l: none;


  /* dynamic color scheme */
  --bgc-nav: var(--bgc-nav-d);
  --bgc: var(--bgc-d);
  --bgc-light: var(--bgc-light-d);
  --bgc-semilight: var(--bgc-semilight-d);
  --bgc-hover: var(--bgc-hover-d);
  --bgc-settings: var(--bgc-settings-d);
  --bgc-input: var(--bgc-input-d);
  --bc: var(--bc-d);
  --bc-chart: var(--bc-chart-d);
  --color: var(--color-d);
  --color-header: var(--color-header-d);
  --color-secondary: var(--color-secondary-d);
  --color-tertiary: var(--color-tertiary-d);
  --color-yellow-text: var(--color-yellow-text-d);
  --box-shadow: var(--box-shadow-d);
  --box-shadow-dark: var(--box-shadow-dark-d);
  --float-border: var(--float-border-d);

  /* standard colors */

  --color-red: rgb(253, 80, 0);
  --color-translucent-red: rgba(253, 80, 0, 0.2);

  --color-red: #f04747;
  --color-translucent-red: rgba(240, 71, 71, 0.2);

  --color-error: #ea374d;
  --color-error: rgb(253, 80, 0); /* robinhood */

  --color-error: var(--color-red);

  --color-success: #0f8a25;
  --color-success: rgb(23, 169, 86);
  --page-link-color: rgb(0, 115, 207);
  --page-link-color: rgb(70,141,228);
  
  /* apple notes yellow */
  --color-yellow: rgb(254, 187, 18);
  --color-translucent-yellow: rgb(254, 187, 18, 0.2);

  --color-strava: #0e7fb6;
  --color-translucent-strava: rgb(14, 127, 182, 0.2);

  --color-green: rgb(14, 137, 37);
  --color-translucent-green: rgb(14, 137, 37, 0.2);
  --color-green: #17a956;
  --color-translucent-green: rgba(23, 169, 86, 0.2);


  --color-yellow: rgb(254, 221, 74);
  --color-translucent-yellow: rgba(254, 221, 74, 0.3);
  --color-yellow-text: #85681d;

  --color-discord: #7288d9;
  --colro-discord: #607de4;
  --color-translucent-discord: rgb(114, 137, 218, 0.25);

  --color-purple: #5469d4;
  --color-translucent-purple: rgb(84, 105, 212, 0.2);

  --color-apple-blue: rgb(38, 135, 250);
  --color-translucent-apple-blue: rgb(38, 135, 250, 0.2);

  --color-rh-blue: rgb(14, 121, 211);
  --color-translucent-rh-blue: rgb(14, 121, 211, 0.2);

  --color-lime: rgb(195,245,60);
  --color-translucent-lime: rgba(195,245,60,0.2);

  --color-teal: rgb(122, 215, 189);
  --color-translucent-teal: rgba(122, 215, 189, 0.2);

  --color-twitter-blue: rgb(30,162,242);
  --color-translucent-twitter-blue: rgba(30,162,242,0.2);

  --color-mint: #189e8a;
  --color-translucent-mint: rgba(24, 158, 138, 0.2);

  --color-default: #5a9ff6;
  --color-translucent-default: rgba(90, 159, 246, 0.2);

  --color-landing-gray: #6f787d;
}



input, input:active, input:focus,
textarea, textarea:active, textarea:focus,
select, select:active, select:focus
 {
  outline: none;
  background-color: transparent;
}
input:focus, input:active,
textarea:active, textarea:focus,
select:active, select:focus
{
  background-color: transparent;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}


/* tooltip */

.tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.tooltip .tooltip-text *{
  color: var(--color-header);
}
.tooltip .tooltip-text {
  /*opacity: 0%;*/
  visibility: hidden;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 30px;
  margin-top: -34px;
  border-radius: 5px;
  background-color: var(--bgc-light);
  border: 1px solid var(--bc-d);
  pointer-events: none;
  position: absolute;
  overflow: auto;
  white-space: nowrap;

  box-shadow: var(--box-shadow-dark);
  border: 1px solid var(--bc);
}


/*  Buttons: 4 main types */
.solid-btn i, .solid-btn img,
.clear-btn i, .clear-btn img,
.solid-btn-secondary i, .solid-btn-secondary img,
.clear-btn-secondary i, .clear-btn-secondary img {
  margin-right: 10px;
}
.solid-btn,
.clear-btn,
.solid-btn-secondary,
.clear-btn-secondary,
.clear-btn-cancel {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  background-color: var(--tint-color);
  color: var(--bgc-light);
  font-size: 17px;
  border-radius: 5px;
  color: var(--bgc-settings);
  border: 1px solid var(--tint-color);
  border-radius: 5px;
}
.clear-btn,
.clear-btn-secondary,
.clear-btn-cancel {
  background-color: transparent;
  color: var(--tint-color);
  border: 1px solid var(--tint-color);

  border: 1px solid var(--bc);
  background-color: var(--bgc-light);
}
.solid-btn-secondary,
.clear-btn-secondary,
.error-btn-secondary,
.clear-btn-cancel {
  padding: 5px 15px;
  font-size: 14px;
}
.solid-btn:hover,
.solid-btn-secondary:hover {
  filter: brightness(80%);
  transition: 0.4s;
}
.clear-btn:hover,
.clear-btn-secondary:hover {
  background-color: var(--tint-color-translucent);
  transition: 0.3s;
}
.clear-btn-cancel {
  background-color: transparent;
  color: var(--tint-color);
  border: none;
}
.clear-btn-cancel:hover {
  background-color: var(--tint-color-translucent);
  transition: 0.3s
}
/* random props */
.onhover-bgc-hover:hover {
  background-color: var(--bgc-hover);
}
.onhover-bgc-tc:hover {
  background-color: var(--tint-color);
}
.onhover-bgc-tct:hover {
  background-color: var(--tint-color-translucent);
}

.onhover-c-tc:hover {
  color: var(--tint-color);
}
.onhover-c-w:hover {
  color: var(--color-header-d) !important;
}

.onhover-bc {
  border: 1px solid transparent;
  border-radius: 5px;
}
.onhover-bc:hover {
  border-color: var(--bc);
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
   -khtml-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}





/* popup windows */
.fullscreen-blur {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(3px) brightness(65%);
  z-index: 21;
}
